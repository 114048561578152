const handleDoubleCheckboxInputClick: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const doubleCheckbox: any = event?.currentTarget || event?.target;
    const doubleCheckboxContainer: any = doubleCheckbox?.closest('.doubleCheckboxInput');
    const doubleCheckboxInput: any = doubleCheckboxContainer?.getElementsByTagName('input')[0];
    const doubleCheckboxes: any = doubleCheckboxContainer?.getElementsByClassName('checkbox');
    const doubleCheckboxValue: string = doubleCheckbox.dataset.value;

    for (let i = 0; i < doubleCheckboxes.length; i++) {
      const doubleCheckboxSingle: any = doubleCheckboxes[i];

      doubleCheckboxSingle.classList.remove('checked');
    }

    doubleCheckbox.classList.add('checked');
    doubleCheckboxContainer.classList.remove('invalid');
    doubleCheckboxInput.value = doubleCheckboxValue;
  } catch (error) {
    console.error(error);
  }
};

const setDoubleCheckboxInputError: (doubleCheckboxInputID: string) => void = doubleCheckboxInputID => {
  try {
    const doubleCheckboxInput: any = document.getElementById(doubleCheckboxInputID);
    const parent: any = doubleCheckboxInput?.closest('.doubleCheckboxInput') || null;

    parent.classList.add('invalid');
  } catch (error) {
    console.error(error);
  }
};

const relocateDoubleCheckboxInputValueByIdToNewInputByIdAndReturnSuccess: (
  doubleCheckboxInputID: string,
  newInputID: string
) => boolean = (doubleCheckboxInputID, newInputID) => {
  try {
    const doubleCheckboxInput: any = document.getElementById(doubleCheckboxInputID);
    const newInput: any = document.getElementById(newInputID);
    const doubleCheckboxInputValue: string = doubleCheckboxInput?.value || '';

    newInput.value = doubleCheckboxInputValue;
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
